import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/Button";
import accounting from "accounting";
import { fetchXenithBankDetails } from "../../redux/actions/xenithActions";

const ReceiveXenith = () => {
  const paymentObj = useSelector((state) => state?.reducer?.checkoutConfig);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [seconds, setSeconds] = useState(600);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Copied to clipboard!");
      },
      () => {
        toast.error("Could not copy text.");
      }
    );
  };

  const getXenithBankDetails = async () => {
    const response = await dispatch(
      fetchXenithBankDetails(paymentObj?.keyUsed, paymentObj?._id)
    );
    if (
      response?.data &&
      Array.isArray(response.data) &&
      response.data.length > 0
    ) {
      setPaymentInfo(response.data[0]);
    } else {
      toast.error("Bank details not available");
    }
  };

  const formatIBAN = (iban) => {
    if (!iban) return iban;
    const first4 = iban.slice(0, 4);
    const last4 = iban.slice(-4);
    return `${first4} **** **** ${last4}`;
  };

  useEffect(() => {
    if (paymentObj) {
      getXenithBankDetails();
    }
  }, [paymentObj]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          navigate("/error/timeout", { state: paymentObj });
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div className="w-full flex flex-col items-center justify-start">
      <div className="mb-3 w-full">
        <div
          className={
            seconds > 10
              ? "timer bg-orange header-right-col"
              : "timer bg-red header-right-col"
          }
        >
          Expires In: {minutes.toString().padStart(2, "0")}:
          {remainingSeconds.toString().padStart(2, "0")}
        </div>
      </div>
      <div className="main-top-section-box w-full flex flex-col items-center relative">
        <h2 className="text-xl font-bold text-center">
          Pay{" "}
          <span className="text-[--primary-color]">
            {paymentObj?.currency}{" "}
            {accounting.formatNumber(paymentObj?.amount, { precision: 2 })}
          </span>
        </h2>
        <p className="text-gray-600 text-center mb-4">
          Pay by Bank Transfer - Fast and Secure
        </p>

        {paymentInfo ? (
          <div className="w-full max-w-md p-4 bg-gray-50 rounded-lg">
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <span className="text-gray-600">BIC</span>
                <div className="flex items-center">
                  <span className="font-medium">{paymentInfo?.bic}</span>
                  <button
                    onClick={() => copyToClipboard(paymentInfo?.bic)}
                    className="ml-2 p-1 bg-gray-200 rounded"
                  >
                    <img src="/copy-icon.svg" alt="copy" className="w-3 h-3" />
                  </button>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-gray-600">IBAN</span>
                <div className="flex items-center">
                  <span className="font-medium">
                    {formatIBAN(paymentInfo?.iban)}
                  </span>
                  <button
                    onClick={() => copyToClipboard(paymentInfo?.iban)}
                    className="ml-2 p-1 bg-gray-200 rounded"
                  >
                    <img src="/copy-icon.svg" alt="copy" className="w-3 h-3" />
                  </button>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-gray-600">Country</span>
                <div className="flex items-center">
                  <span className="font-medium">{paymentInfo?.country}</span>
                  <button
                    onClick={() => copyToClipboard(paymentInfo?.country)}
                    className="ml-2 p-1 bg-gray-200 rounded"
                  >
                    <img src="/copy-icon.svg" alt="copy" className="w-3 h-3" />
                  </button>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-gray-600">Currency</span>
                <div className="flex items-center">
                  <span className="font-medium">{paymentInfo?.currency}</span>
                  <button
                    onClick={() => copyToClipboard(paymentInfo?.currency)}
                    className="ml-2 p-1 bg-gray-200 rounded"
                  >
                    <img src="/copy-icon.svg" alt="copy" className="w-3 h-3" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full max-w-md p-4 bg-gray-50 rounded-lg flex justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[--primary-color]"></div>
          </div>
        )}

        <p className="text-blue mt-4 cursor-pointer">
          To receive a payment confirmation <br />
          <Button
            // onClick={handleMadePayment}
            className="!mt-5"
          >
            Made Payment
          </Button>
        </p>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ReceiveXenith;
