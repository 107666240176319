import React, { Fragment, useState, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { customerAuth } from "../../redux/actions/customerActions";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import Button from "../common/Button";
import ReCAPTCHA from "react-google-recaptcha";

const Signin = () => {
  const [inputType, setInputType] = useState("email");
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const phoneInputStyle = {
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "0.375rem",
    color: "white",
    fontSize: "1rem",
    width: "100%",
    height: "100%",
  };

  const phoneContainerStyle = {};

  const phoneDropdownStyle = {
    backgroundColor: "white",
    color: "black",
  };

  const flagButtonStyle = {
    backgroundColor: "transparent",
    border: "none",
    borderRight: "1px solid transparent",
  };

  const phoneDropdownCountryStyle = {
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
  };

  const phoneHighlightStyle = {
    backgroundColor: "white",
    color: "black",
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value.trim());
    setError("");
  };

  const handlePhoneChange = (value) => {
    setInputValue(value);
    setError("");
  };

  const toggleInputType = (type) => {
    setInputType(type);
    setInputValue("");
    setError("");
    setCaptchaValue(null);

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    // Clear any reCAPTCHA-related error when captcha is completed
    if (value) {
      setError("");
    }
  };

  const handleOTP = async () => {
    // Validate input value
    if (!inputValue) {
      setError(
        `Please enter ${inputType === "email" ? "email" : "phone number"}.`
      );
      return;
    }

    // Validate reCAPTCHA
    if (!captchaValue) {
      setError("Please complete the reCAPTCHA verification");
      return;
    }

    setLoading(true);
    try {
      const response = await dispatch(
        customerAuth({
          to: inputValue,
          captcha: captchaValue,
        })
      );

      if (response.code === 1) {
        navigate("/auth/otp");
      } else {
        showErrorMessage(response.data);
        // Reset captcha on failure
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setCaptchaValue(null);
      }
    } catch (err) {
      showErrorMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showErrorMessage = (errMessage) =>
    toast.error(errMessage, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "colored",
      transition: Bounce,
    });

  return (
    <Fragment>
      <div className="w-full h-full flex flex-col items-center justify-center pb-[75px] signin-page-extra-pb">
        <a href="/main/dashboard/" className="logo-link">
          <img
            src="/banksy-black-logo.svg"
            alt="logo"
            className="w-full logo-transparent-white"
          />
        </a>
        <p className="bnksy-page-title mt-8 mb-[12px]">Sign in </p>

        {/* New tab-like button */}
        <div className="flex mb-5 bnksy-tabs-box">
          <button
            className={`signin-tab-btn w-[80px] h-[32px] flex items-center justify-center rounded-full common-lbl ${
              inputType === "email" ? "tab-active" : ""
            }`}
            onClick={() => toggleInputType("email")}
          >
            Email
          </button>
          <button
            className={`signin-tab-btn w-[80px] h-[32px] flex items-center justify-center rounded-full common-lbl ${
              inputType === "phone" ? "tab-active" : ""
            }`}
            onClick={() => toggleInputType("phone")}
          >
            Phone
          </button>
        </div>

        <div className="w-full">
          <div className="w-full bnksy-input-group">
            {inputType === "email" ? (
              <input
                type="email"
                className="bnksy-form-input"
                id="signup-input"
                placeholder="Email address"
                value={inputValue}
                onChange={handleInputChange}
                required
              />
            ) : (
              <PhoneInput
                country={"us"}
                value={inputValue}
                onChange={handlePhoneChange}
                inputProps={{
                  id: "signup-input",
                  required: true,
                  placeholder: "Phone Number",
                  className: "bnksy-form-input !pl-[50px] phone-input-box",
                }}
                inputStyle={phoneInputStyle}
                containerStyle={phoneContainerStyle}
                dropdownStyle={phoneDropdownStyle}
                buttonStyle={flagButtonStyle}
                dropdownCountryStyle={phoneDropdownCountryStyle}
                highlightStyle={phoneHighlightStyle}
              />
            )}
          </div>

          {/* reCAPTCHA Component */}
          <div className="flex flex-col items-center my-4">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleCaptchaChange}
            />
            {error && (
              <div className="text-red-600 font-small mt-2 text-center">
                {error}
              </div>
            )}
          </div>

          <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
            <Button
              onClick={handleOTP}
              disabled={!inputValue || !captchaValue}
              loading={loading}
            >
              Send OTP
            </Button>
          </div>
          <ToastContainer position="bottom-right" />
        </div>
      </div>
    </Fragment>
  );
};

export default Signin;