import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RootLayout from "../../../component/layout";
import { getPayment } from "../../../network/api";
import Awaiting from "../../../component/common/Awaiting";

const XenonPaymentStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [paymentId, setPaymentId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
        const searchObject = {};
        const searchParams = new URLSearchParams(location.search);
        for (const [key, value] of searchParams.entries()) {
          searchObject[key] = value;
        }
        if (
          !searchObject.hasOwnProperty("key") ||
          !searchObject.hasOwnProperty("paymentId")
        ) {
          navigate("/error/config");
        } else {
            setKey(searchObject.key);
            setPaymentId(searchObject.paymentId);
          const status = await getPayment(
            searchObject.key,
            searchObject.paymentId
          );
          if (status?.status === "success") {
            window.location.assign(
              status.successCallback + "?paymentId=" + status._id
            );
          } else if(status?.status === "failure") {
            window.location.assign(
              status.failureCallback + "?paymentId=" + status._id
            );
          } else {

          }
        }
    };
    fetchData();

    const intervalId = setInterval(fetchData, 5000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [location.search, navigate]);

  const successFallback = async () => {

    if (key.length === 0 || paymentId.length === 0) {
      navigate("/error/config");
    } else {
      const status = await getPayment(key, paymentId);
      window.location.assign(
        status.successCallback + "?paymentId=" + status._id
      );
    }
  };

  return (
    <RootLayout>
      <Awaiting
        buttonText={"Go back"}
        onButtonClick={successFallback}
        title="Please wait..."
        description="It will take time to process your payment"
        imageSource="/Please-Wait.png"
      />
    </RootLayout>
  );
};

export default XenonPaymentStatus;


